import React from "react";
import axios from "axios";
import { Container,Row,Col,Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookSquare,  faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import parse from 'html-react-parser';
function Team(Content){
    Content=Content.Content

    const baseUrl = process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/teams/';

    const [teamimages, setTeamimages] = React.useState(null);
    const team_arr = [7,4,1,2,3,6]
    React.useEffect(() => {
        axios.get(baseUrl).then((response) => {
            setTeamimages(response.data);
        });
    }, []);
    if (!teamimages) 
        return null;

    var team_list = []

    team_arr.forEach(item => {
        var member = '';
        teamimages.forEach(teamitem=> {
            if(teamitem.id === item){
                member = <Col xl={4} md={6} lg={4} key={teamitem.id}><div className="single_lawyer text-center"><div className="thumb"><Image src={teamitem.image_url} alt="Image" /></div><h3>{teamitem.name}</h3><span>{teamitem.designation}</span><div className="social_links"><ul><li><a href={teamitem.fb_url}> <FontAwesomeIcon icon={faFacebookSquare}  className="fa-2xl" /> </a></li><li><a href={teamitem.instagram_url}> <FontAwesomeIcon icon={faInstagramSquare} className="fa-2xl"/> </a></li><li><a href={teamitem.x_url}> <FontAwesomeIcon icon={faTwitterSquare} className="fa-2xl"/> </a></li></ul></div></div></Col>
            }
        });

        
        team_list.push(member)
    
    });
   
   
   /*  const team = teamimages.map((teamimage,index)=>{
        if( teamimage.id != 5 && team_arr.includes(teamimage.id)){
            return(
            <Col xl={4} md={6} lg={4} key={teamimage.id}>
            <div className="single_lawyer text-center">
                <div className="thumb">
                    <Image src={teamimage.image_url} alt="Image" />
                </div>
                <h3>{teamimage.name}</h3>
                <span>{teamimage.designation}</span>
                <div className="social_links">
                    <ul>
                        <li><a href={teamimage.fb_url}> <FontAwesomeIcon icon={faFacebookSquare}  className="fa-2xl" /> </a></li>
                        <li><a href={teamimage.instagram_url}> <FontAwesomeIcon icon={faInstagramSquare} className="fa-2xl"/> </a></li>
                        <li><a href={teamimage.x_url}> <FontAwesomeIcon icon={faTwitterSquare} className="fa-2xl"/> </a></li>
                    </ul>
                </div>
            </div>
            </Col>
            )
        }

        return('');
       
 } ) */

    return(
        <div className="team-container" id="team">
            <Container>
                <Row>
                    <Col xl={12}>
                            <div className="section_title text-center mb-60">
                            {parse(Content.team_heading)}
                                        
                            {parse(Content.team_sub_heading)}
                                
                                </div>
                    </Col>
                </Row>
                <Row>
                {team_list}
                   {/*  <Col xl={4} md={6} lg={4}>
                        <div className="single_lawyer text-center">
                            <div className="thumb">
                                <Image src="img/lawyers/1.png" alt="Image" />
                            </div>
                            <h3>Henry Miller</h3>
                            <span>Family Lawyer</span>
                            <div className="social_links">
                                <ul>
                                    <li><a href="#fb"> <FontAwesomeIcon icon={faFacebookSquare} color="#F2C64D" className="fa-2xl" /> </a></li>
                                    <li><a href="#insta"> <FontAwesomeIcon icon={faInstagramSquare} color="#F2C64D" className="fa-2xl"/> </a></li>
                                    <li><a href="#twit"> <FontAwesomeIcon icon={faTwitterSquare} color="#F2C64D" className="fa-2xl"/> </a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4} md={6} lg={4}>
                        <div className="single_lawyer text-center">
                            <div className="thumb">
                                <Image src="img/lawyers/1.png" alt="Image" />
                            </div>
                            <h3>Henry Miller</h3>
                            <span>Family Lawyer</span>
                            <div className="social_links">
                                <ul>
                                    <li><a href="#fb"> <FontAwesomeIcon icon={faFacebookSquare} color="#F2C64D" className="fa-2xl" /> </a></li>
                                    <li><a href="#insta"> <FontAwesomeIcon icon={faInstagramSquare} color="#F2C64D" className="fa-2xl"/> </a></li>
                                    <li><a href="#twit"> <FontAwesomeIcon icon={faTwitterSquare} color="#F2C64D" className="fa-2xl"/> </a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4} md={6} lg={4}>
                        <div className="single_lawyer text-center">
                            <div className="thumb">
                                <Image src="img/lawyers/1.png" alt="Image" />
                            </div>
                            <h3>Henry Miller</h3>
                            <span>Family Lawyer</span>
                            <div className="social_links">
                                <ul>
                                    <li><a href="#fb"> <FontAwesomeIcon icon={faFacebookSquare} color="#F2C64D" className="fa-2xl" /> </a></li>
                                    <li><a href="#insta"> <FontAwesomeIcon icon={faInstagramSquare} color="#F2C64D" className="fa-2xl"/> </a></li>
                                    <li><a href="#twit"> <FontAwesomeIcon icon={faTwitterSquare} color="#F2C64D" className="fa-2xl"/> </a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>   */}                
                </Row>
            </Container>
        </div>
    )
}

export default Team;