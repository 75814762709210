import React, { useState } from "react";
import axios from "axios";
import { Container, Row, Col,Navbar, Form, FormControl, Button, Image, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookSquare,  faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {  faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';

function Footer(Content){

    const baseUrl = process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/subscribers/'
    const [show, setShow] = useState(false);
    const addSubscriber = (e) => {
        e.preventDefault();
        axios({
            method:'post',
            url:baseUrl,
            data:e.target
        }).then((response)=>{
            setShow(true)
            console.log(response)
        })

    }

    const handleClose = () =>{
        setShow(false);
        window.location.reload(true);
    } 

    Content=Content.Content
    return(
        <div className="footer-container">
            <div className="footer-top">
                <Container>
                    <Row>
                        <Col xl={4} md={6} lg={3}>
                            <div className="footer_widget">
                                <div className="footer_logo">
                                    <Navbar.Brand href="#home" >
                                    <Image
                                    src="adv-logo.png"
                                    className="d-inline-block align-top"
                                    alt="logo"
                                    width={'40px'}
                                    height={'40px'}
                                    />
                                    <Image
                                    src="rana-associates-header-logo.png"
                                    className="d-inline-block align-middle"
                                    width={'230px'}
                                    alt="logo"
                                    style={{ marginTop:'7px' }}
                                    />
                                    </Navbar.Brand>
                                </div>
                                <p className="footer_text"> 
                                    {parse(Content.footer_address)}
                                    <br />
                                    {parse(Content.footer_phone)}
                                     <br />
                                    <a className="domain" href="#email">{parse(Content.footer_email)}</a></p>
                                <div className="socail_links ">
                                    <ul>
                                        <li className="px-2">
                                            <a href={Content.fb_url} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFacebookSquare}  className="fa-xl" />
                                            </a>
                                        </li>
                                        <li className="px-2">
                                            <a href={Content.x_url} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faTwitterSquare}  className="fa-xl"/>
                                            </a>
                                        </li>
                                        <li className="px-2">
                                            <a href={Content.instagram_url} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faInstagramSquare}  className="fa-xl"/>
                                            </a>
                                        </li>
                                    </ul>

        
                                </div>
                            </div>
                        </Col>
                        <Col xl={2} md={6} lg={2}>
                            <div className="footer_widget">
                                <h3 className="footer_title">
                                       Services
                                </h3>
                                <ul>
                                    <li><a href="#service">GST Regitration</a></li>
                                    <li><a href="#service">FSSAI License</a></li>
                                    <li><a href="#service">TRADEMARK</a></li>
                                    <li><a href="#service">Pvt Ltd Company</a></li>
                                </ul>
                            </div>              
                        </Col>
                        <Col xl={2} md={6} lg={2}>
                            <div className="footer_widget">
                                <h3 className="footer_title">
                                       Useful Links
                                </h3>
                                <ul>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#about">About</a></li>
                                    <li><a href="#team">Team</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </ul>
                            </div>              
                        </Col>
                        <Col xl={4} md={6} lg={4}>
                            <div className="footer_widget">
                                <h3 className="footer_title">
                                        Subscribe
                                </h3>
                                <Form className="newsletter_form" onSubmit={addSubscriber}>
                                    <FormControl type="text"  placeholder="Enter your mail" name='email' />
                                    <Button type="submit">Sign Up</Button>
                                </Form>
                                <p className="newsletter_text">Subscribe newsletter to get updates</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom text-center">
                <Container>
                    <span className="text-muted">Powered by <strong>VISOTECQ,NOIDA</strong>&copy;2024</span>
                </Container>
            </div>
            <Modal show={show} >
                <Modal.Header>
                        <FontAwesomeIcon icon={faCheckCircle} color="white" className="fa-2xl" />
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4>Great!</h4>	
                    <p>Your Subscription Successful.
                    < br />We will Contact you soon.</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal> 
        </div>
    )
}

export default Footer;