import React, { useState } from "react";
import {Container, Row, Col, Image, Form, Button, InputGroup, Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import parse from 'html-react-parser';

function CalenderIcon({value,onClick}){
    return(
        <InputGroup>
            <Form.Control type="text" value={value} onClick={onClick} name="appoint_date" readOnly />
            <div className="input-group-append">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faCalendarAlt} color="#F2C64D" className="fa-xl" />
                </InputGroup.Text>
            
            </div>
        </InputGroup>
            
    )
}
function Contact(Content){

    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);

    const sendEmail = (e) =>{
        e.preventDefault();
        emailjs.sendForm('service_ygkval9','template_7sizcib',e.target,'BibvscZA3MBAB3Gb0').then(
            (response) => {
                if(response.status===200){
                    setShow(true);
                }
                   
            },
            (error) => {
                    setShow(false);
            },
          );
    }

    const handleClose = () =>{
        setShow(false);
        window.location.reload(true);
    } 

    Content=Content.Content
    return(
        <div className='contact-container' id='contact'>
            <Container>
                <Row className="align-items-center">
                    <Col xl={5} md={6} lg={6}>
                        <div className="appiontment_thumb d-none d-lg-block">
                            <Image src={Content.contact_image_url} alt="Image" />
                        </div>
                    </Col>

                    <Col xl={6} md={6} lg={12} className='offset-xl-1'>
                        <div className="appointment_info contact-appoint">
                        {parse(Content.contact_heading)}
                                        
                        {parse(Content.contact_sub_heading)}
                           
                             <Form onSubmit={sendEmail}>
                                    <Row>
                                        <Col xl={6} md={6}>
                                            <Form.Control type="text" placeholder="Name" name="from_name" />
                                        </Col>
                                        <Col xl={6} md={6}>
                                            <Form.Control type="email" placeholder="Your Email" name="from_email" />
                                        </Col>
                                        <Col xl={6} md={6}>
                                            <Form.Control type="text" placeholder="Phone No." name="mobile"/>
                                        </Col>
                                        <Col xl={6} md={6}>
                                            <label className="w-100">
                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="Appointment Date" id="appointment-date" customInput={<CalenderIcon/>} />
                                            </label>
                                           
                                        </Col>
                                        <Col xl={12}>
                                            <Form.Control name="message" type="text" placeholder="Message"  as="textarea"  rows={3}/>
                                        </Col>
                                        <Col xl={12}>
                                            <div className="appoinment_button">
                                                <Button className='boxed-btn5 ' type="submit">
                                                    Free Consultation
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
            
                                </Form>
                        </div>
                    </Col>

                </Row>
            </Container>

             <Modal show={show} >
                <Modal.Header>
                        <FontAwesomeIcon icon={faCheckCircle} color="white" className="fa-2xl" />
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4>Great!</h4>	
                    <p>Your Message has been Sent Successfully.
                    < br />We will Contact you soon.</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal> 

          

        </div>

    )
}

export default Contact;