import {Container,Navbar,Nav,Image} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookSquare,  faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

function Header(Content) {
  Content = Content.Content
  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top" bg="dark" data-bs-theme="light">
      <Container>
        <Navbar.Brand href="#home" >
          
        <Image
              src="adv-logo.png"
              className="d-inline-block align-top"
              alt="logo"
              width={'40px'}
              height={'40px'}
            />
        <Image
              src="rana-associates-header-logo.png"
              className="d-inline-block align-middle"
              alt="logo"
              width={'230px'}
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#service">Services</Nav.Link>
            <Nav.Link href="#team">Team</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            
          </Nav>

        </Navbar.Collapse>

        
        <Navbar.Collapse className="justify-content-end ">
          <Navbar.Text className='social-icons'>
          <a href={Content.fb_url} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookSquare}  className="fa-xl"/>
          </a>
          
          <a href={Content.x_url} target="_blank" rel="noreferrer" >
            <FontAwesomeIcon icon={faTwitterSquare}  className="fa-xl"/>
          </a>
          <a href={Content.instagram_url} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagramSquare} className="fa-xl"/>
          </a>
        {/*   <a href="#dribble" >
            <FontAwesomeIcon icon={faYoutubeSquare} />
          </a> */}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;