import Carousel from 'react-bootstrap/Carousel';
import {Col, Container, Image, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import axios from "axios";
import parse from 'html-react-parser';
function Reviews() {
    const baseUrl = process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/reviews/';

const [reviews, setReviews] = useState(null);
useEffect(  () => {
    axios.get(baseUrl).then((response) => {
        setReviews(response.data);
    });
  }, []);
 // console.log(reviews)

  if (!reviews) 
    return null;

const reviewContent = reviews.map((item) => {

    return (
        <Carousel.Item key={item.id}>
            <div className="single_testmonial text-center">
            <FontAwesomeIcon icon={faQuoteLeft} color='black' className='fa-2xl' />
                <p>{parse(item.text_content)}</p>
                <div className="author_info d-flex justify-content-center align-items-center">
                    <div className="thumb">
                        <Image src={item.image_url} alt="Image" height={'40px'} />
                    </div>
                    <span>- {item.name}</span>
                </div>
            </div>
        </Carousel.Item>
    )
  
  
  } );
  return (
    <div className='reviews-container reviews-bg-img reviews-overlay'>
        <Container>
            <Row>
                <Col xl={12} >
                    <Carousel indicators={false}>
                        {reviewContent}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    </div>
    );
    }

export default Reviews;