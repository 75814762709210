import { Modal,Button } from "react-bootstrap";
import parse from 'html-react-parser';
function Details (content){
   
function handleGetAppoint(){
    var element = document.getElementById("contact");
    var close_btn = document.getElementById("close");
    element.scrollIntoView();
   close_btn.click()
    
}
  
return (
                <Modal show={content.show}  onHide={content.hide} >
                    <Modal.Header closeButton style={{ background:'#fff' ,borderBottom:'1px solid' }}>
                        <Modal.Title > {parse(content.content)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >{parse(content.descp)}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={content.hide} id='close'>Close</Button>
                        <Button variant="dark" onClick={handleGetAppoint}>Get Appointment</Button>
                    </Modal.Footer>
                </Modal>
)
}

export default Details;