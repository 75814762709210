import React from "react";
import axios from "axios";
import { Col, Row, Image, Container } from 'react-bootstrap';
import parse from 'html-react-parser';
function About(Content){
    Content=Content.Content

    const baseUrl =process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/teams/';

    const [teamimages, setTeamimages] = React.useState(null);

    React.useEffect(() => {
        axios.get(baseUrl).then((response) => {
            setTeamimages(response.data);
        });
    }, []);
    if (!teamimages) 
        return null;

    const team = teamimages.map((teamimage)=>{
        // console.log(teamimage.id<4)
         if( teamimage.id === 5){
           return( <Col xl={6} md={6} key={teamimage.id}>
                <div className="single_about_info text-center">
                    <div className="about_thumb">
                        <Image src={teamimage.image_url} alt="Image" />
                    </div>
                  
                    {parse(Content.about_heading_1)}
                                
                    {parse(Content.about_sub_heading_1)}
                   
                    {/* <div className="signature">
                        <Image src="img/about/signature.png" alt="Image" />
                    </div> */}
                </div>
        
                          
            </Col>
           );
         }
         return ('')
        
  } )
    return (

    <div className='about-container' id='about'>
        
            <Container >
                <Row>
                    {team}
                 {/*    <Col xl={6} md={6}>
                        <div className="single_about_info text-center">
                            <div className="about_thumb">
                                <Image src={teamimages.image_url} alt="Image" />
                            </div>
                          
                            {parse(Content.about_heading_1)}
                                        
                            {parse(Content.about_sub_heading_1)}
                           
                            {/* <div className="signature">
                                <Image src="img/about/signature.png" alt="Image" />
                            </div> 
                        </div>
                
                                  
                    </Col>
                    <Col xl={6} md={6}>
                        <div className="single_about_info text-center">
                            <div className="about_thumb">
                                <Image src={teamimages.image_url} alt="Image" />
                            </div>
                          
                            {parse(Content.about_heading_1)}
                                        
                            {parse(Content.about_sub_heading_1)}
                           
                            {/* <div className="signature">
                                <Image src="img/about/signature.png" alt="Image" />
                            </div> 
                        </div>
                
                                  
                    </Col> */}

                    <Col xl={6} md={6}>
                        <div className="single_about_info text-center">
                            <div className="about_thumb">
                                <div className="image_hover">
                                    <div className="hover_inner">
                                    {parse(Content.about_heading_2)}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            {parse(Content.about_sub_heading_2)}
                            <br />
                            <div className="total_cases">
                                <div className="single_cases">
                                    <h4>500+</h4>
                                    <h2><strong>Satisfied Clients</strong></h2>
                                </div>
                                <div className="single_cases">
                                    <h4>10+</h4>
                                    <h2><strong>Team</strong></h2>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
    </div>
    )
}

export default About;