import {useState, useEffect} from "react";
import axios from "axios";
import { Col, Row,Image, Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import Details from "./Details";
function Services(Content){
    Content=Content.Content

    const baseUrl = process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/services/';

    const [services, setServices] = useState(null);
    const [show, setShow] = useState(false);
    const [content, setContent] = useState('');
    const [descp, setDescp] = useState('');


    const showDetails = (content,descp)=>{
            setContent(content);
            setDescp(descp)
            setShow(true)
    }
    useEffect(() => {
        axios.get(baseUrl).then((response) => {
            setServices(response.data);
        });
      }, []);
      if (!services) 
        return null;


      const serviceList = services.map((service) => 
       
        <Col xl={3} md={6} key={service.id} >
        <div className="service-box">
            <div className="service-image">
                <Image src="rana-associates-bg.png" alt="Image" />
            </div>
            <div className="service-hover text-center">
            
                <div className="hover_inner">{/* 
                <FontAwesomeIcon icon={faBriefcase} color='#F2C64D' className='fa-2xl' /> */}
                <Image src={service.image_url} alt="Image" width={'85px'} height={'85px'} />
                    {parse(service.heading)}
                    <p>
                    {service.description}
                    <br/> 
                    <strong>Rs.{service.price}/-*</strong>
                    </p>
                    <a href={'#details'+service.id} className="learn_more" onClick={()=> showDetails(service.heading,service.details)} id={"link"+service.id}>Learn More</a>

                    

               
       
                </div>
            </div>
        </div>

            
                
        </Col>

      );
    return (
      
    <div className='services-container' id='service' >
        
            <Container className='p-0'>
                <Row>
                    <Col xl={12} >
                        <div className="services-title text-center mb-60">
                                        {parse(Content.services_heading)}
                                        
                                        {parse(Content.services_sub_heading)}
                                       
                          
                              </div>     
                    </Col>

                   
                </Row>

                <Row className='no-gutters'>
                   {serviceList}
                {/*     <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xl={3} md={6} >
                    <div className="service-box">
                        <div className="service-image">
                            <Image src="rana-associates-bg.png" alt="Image" />
                        </div>
                        <div className="service-hover text-center">
                            <div className="hover_inner">
                                <h3>Business Law</h3>
                                <p>There are many variations of passages of <br /> Lorem Ipsum available, <br /> but the
                                    majority have suffered</p>
                                <a href="#learn" className="learn_more">Learn More</a>
                            </div>
                        </div>
                    </div>
                    </Col> */}
                </Row>
            </Container>
            <Details content={content} descp={descp} show={show} hide={()=>setShow(false)} />
    </div>
    )
}

export default Services;