import React from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Team from './components/Team';
import Reviews from './components/Reviews';
import Contact from './components/Contact';
import Footer from './components/Footer';
function App() {
  React.useEffect(() => {
    document.title = "Rana Associates"
  }, [])

  const baseUrl =process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/pagecontents/1/';
  const [content, setContent] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseUrl).then((response) => {
      setContent(response.data);
    });
  }, []);
  if (!content) 
    return null;
  return (
   <>
    <Header Content={content}/>
    <Home Content={content} />
    <About Content={content}/>
    <Services Content={content} />
    <Team Content={content}/>
    <Reviews />
    <Contact Content={content} />
    <Footer Content={content} />
   </>
  );
}

export default App;
