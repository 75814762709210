import { useEffect, useState } from 'react';
import axios from "axios";
import {  Container, Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import parse from 'html-react-parser';
function Home(Content){
Content = Content.Content

const baseUrl = process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/homesliderimages/';
const homeSliderTextUrl = process.env.REACT_APP_LOCAL_BACKEND_URL+'/api/homeslidertexts/';

const [sliderimages, setSliderimages] = useState(null);
const [slidertextcontent, setSliderTextContent] = useState(null);


useEffect(  () => {
  axios.get(homeSliderTextUrl).then((response) => {
    setSliderTextContent(response.data);
  });
}, []);

var homeslidertext = ''
if(slidertextcontent != null){

homeslidertext = slidertextcontent.map((item) => {

  return (
    <Carousel.Item className="slider-image" key={item.id}>
    <Container className='slider-txt-container'/>
     <Carousel.Caption className='slider-txt'>
                          <Image src={item.image_url}  alt='Gst Image'  />
                          {parse(item.text_content)}
     </Carousel.Caption>
   </Carousel.Item>
  )


} );
}


useEffect(() => {
    axios.get(baseUrl).then((response) => {
      setSliderimages(response.data);
    });
  }, []);
  if (!sliderimages) 
    return null;

  const homeslider = sliderimages.map((image) => {

       return (
           <Carousel.Item key={image.id} className="slider-image">
          <Image src={image.image_url}  />
          <Carousel.Caption className='slider-txt'>
                              {/*     {parse(Content.home_heading)}
                                  
                                  {parse(Content.home_sub_heading)} */}
          </Carousel.Caption>
        </Carousel.Item>
       )


 } );


    return (

    <div className='slider-container' id="home" >
        {/* <div className='slider-container-inner  d-flex align-items-center' style={{ backgroundImage:`url(${Content.home_image_url})` }} >
            <Container >
                <Row>
                    <Col xl={7} className='slider-txt'>

                
                                   {parse(Content.home_heading)}
                                        
                                   {parse(Content.home_sub_heading)}
                                    <a href="#about" className="boxed-btn4 ">Learn More</a>


                    </Col>
                </Row>
            </Container>
        </div> */}

        <Carousel indicators={false}  interval={3000}>
             {/*  <Carousel.Item className="slider-image">
               <Container style={{height:'650px'}}/>
                <Carousel.Caption className='slider-txt'>
                                     {parse(Content.home_heading)}
                                        
                                        {parse(Content.home_sub_heading)}
                </Carousel.Caption>
              </Carousel.Item> */}
              {homeslidertext}
             {homeslider} 
            </Carousel>
    </div>
    )
}

export default Home;